import Excel from 'exceljs'
import { saveAs } from 'file-saver'
export const Mixin = {
  data() {
    return {
      Excel: Excel,
      saveAs: saveAs,
      // hostname: 'http://192.168.1.231:8080'
      hostname: 'https://www.bamb.asia'
      // hostname:'http://192.168.1.240:8080'
    }
  },
  methods: {
    dwnExcel(datas, fileName, sheetNames, mergeCells) {
      // mergeCells: [ {Srow:10,Scol:11,Erow:12,Ecol:13}]
      // console.log(datas, fileName, sheetNames, mergeCells)
      var workbook = new Excel.Workbook()
      for (const [i, name] of sheetNames.entries()) {
        const sheet = workbook.addWorksheet(`${name}`)
        let numArr = []
        for (const data of datas[i]) {
          let item = []
          for (const d of data) {
            if(isFinite(d) && d!=='') item.push(d*1)
            else item.push(d)
          }
          numArr.push(item)
        }
        sheet.addRows(numArr)
        // console.log('addRows')
        if (mergeCells.length > 0) {
          // console.log(mergeCells[i])
          for (const mergeCell of mergeCells[i]) {
            // console.log(mergeCell)
            sheet.mergeCells(
              mergeCell.Srow,
              mergeCell.Scol,
              mergeCell.Erow,
              mergeCell.Ecol
            )
          }
        }
      }

      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, `${fileName}.xlsx`)
      })
    },
    Mosaic(oldval, sessionid) {
      if (sessionid === '3294228999') {
        // let newval = oldval.split('')
        // if (newval.length > 0) {
        //   const t = newval.map((str, i) => {
        //     return (str = '○○○')
        //   })
        //   return t.join('')
        // }
        return '○○○'
      }
      return oldval
    },
    fetchWithTimeout(year, month, rerpot_id) {
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const _hostname = `${this.hostname}/app/hermers/httpreporter`
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: `sessionid=${s_id}&supplier_uid=${s_uid}&report_nid=${rerpot_id}&yyyy=${year}&m=${month}`
      }
      return new Promise((resolve, reject) => {
        // Set timeout timer
        let timer = setTimeout(
          () => reject(new Error('Request timed out')),
          3 * 60 * 1000
        )

        fetch(_hostname, config)
          .then(response => resolve(response))
          .finally(() => clearTimeout(timer))
      })
    },
    signInHandler(config) {
      const _hostname = `${this.hostname}/app/hermers/login`
      return new Promise((resolve, reject) => {
        fetch(_hostname, config).then(
          response => resolve(response),
          err => reject(err)
        )
      })
    },
    getSupplierInfo(config) {
      const _hostname = `${this.hostname}/app/hermers/supplier_select_one`
      return new Promise((resolve, reject) => {
        fetch(_hostname, config).then(
          response => resolve(response),
          err => reject(err)
        )
      })
    },
    RemoveNonValidJSONChars(val) {
      let string = val
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f')
      // remove non-printable and other non-valid JSON chars
      string = string.replace(/[\u0000-\u0019]+/g, '')
      return string
    }
  }
}
