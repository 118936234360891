<template>
  <div id="app">
    <router-view />
    <div class="wrap" v-if="isShow">
      <div class="out">
        <span>Loading...</span>
      </div>
    </div>
    <div class="logOutwrap" v-if="logOut">
      <div class="cssload-loader"></div>
    </div>
    <div class="bg" v-if="serverDown">
      <div class="loader">Server down ...</div>
    </div>
    <Setcontrol></Setcontrol>
  </div>
</template>
<script>
import Setcontrol from '@/components/Setcontrol'
export default {
  components: {
    Setcontrol,
  },
  data() {
    return {
      isShow: false,
      logOut: false,
      serverDown: false
    }
  },
  methods: {
    loadingHandler(...args) {
      this.isShow = args[0]
    },
    logOutHandler(...args) {
      this.logOut = args[0]
    },
    serverDownHandler(...args) {
      this.serverDown = args[0]
    }
  },
  created() {
    this.$eventBus.$on('loadingHandler', this.loadingHandler, this)
    this.$eventBus.$on('logOutHandler', this.logOutHandler, this)
    this.$eventBus.$on('serverDownHandler', this.serverDownHandler, this)
  }
}
</script>
<style lang="scss">
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
#app {
  font-size: 0.875rem;
  font-family: 微軟正黑體, arial !important;
  background-color: #eef5f9 !important;
}
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #34495e;
  opacity: 0.7;
  z-index: 99999;
}

.out {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-sizing: border-box;
  border-top: 10px solid #e67e22;
  position: relative;
  animation: a1 2s linear infinite;

  &::before,
  &::after {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    top: -10px;
    border-radius: 50%;
    box-sizing: border-box;
  }
  &::before {
    border-top: 10px solid #2980b9;
    transform: rotate(120deg);
  }
  &::after {
    border-top: 10px solid #8e44ad;
    transform: rotate(240deg);
  }
  span {
    position: absolute;
    width: 200px;
    height: 200px;
    color: #aaa;
    text-align: center;
    line-height: 200px;
    animation: a2 2s linear infinite;
    font-size: 18px;
  }
}

@keyframes a1 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes a2 {
  to {
    transform: rotate(-360deg);
  }
}
.logOutwrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #34495e;
  opacity: 0.7;
  z-index: 99999;
}
.cssload-loader {
  position: relative;
  width: 360px;
  height: 40px;
  background-color: rgba(173, 138, 138, 0.2);
}

.cssload-loader:before {
  content: '';
  position: absolute;
  background-color: rgb(0, 0, 0);
  top: 0px;
  left: 0px;
  height: 40px;
  width: 0px;
  z-index: 0;
  opacity: 1;
  transform-origin: 100% 0%;
  animation: cssload-loader 2.5s ease-in-out infinite;
}

.cssload-loader:after {
  padding: 10px;
  content: 'Please login again . . .';
  text-transform: uppercase;
  color: #fff;
  font-family: Lato, 'Helvetica Neue';
  font-weight: 200;
  font-size: 28px;
  line-height: 40px;
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 19px;
  left: 0;
  top: 0;
}

@keyframes cssload-loader {
  0% {
    width: 0px;
  }
  70% {
    width: 100%;
    opacity: 1;
  }
  90% {
    opacity: 0;
    width: 100%;
  }
  100% {
    opacity: 0;
    width: 0px;
  }
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  background: #34495e;
  z-index: 9;
}
.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #c0392b;
  letter-spacing: 0.2em;
  font-size: 14px;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: #c0392b;
    position: absolute;
    animation: load 0.7s infinite alternate ease-in-out;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 40px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 24px;
  z-index: 99999999999999;
}
:-webkit-scrollbar-track:horizontal {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-track {
  background-color: #eef5f9;
}

::-webkit-scrollbar-thumb {
  background-color: #4e6790;
}

::-webkit-scrollbar-button {
  background-color: #eef5f9;
}

::-webkit-scrollbar-corner {
  background-color: #eef5f9;
}
</style>
