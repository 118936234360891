<script>
import { Container, Main, Row, Col, Table, TableColumn, Button,Drawer,Switch,Select, Option,Input } from 'element-ui'
import { Mixin } from '@/mixins/util.js'
export default {
  name: 'setcontrol',
  mixins: [Mixin],
  components: {
    'el-drawer': Drawer,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-main': Main,
    'el-row': Row,
    'el-col': Col,
    'el-switch': Switch,
    'el-select': Select,
    'el-option': Option,
    'el-input': Input,
  },
  data() {
    return {
      isShow: false,
      isNewPage: false,
      list:[],
      type:'1',
      filterCode:'',
      options:[
        {label:'セット',value:'1'},
        {label:'単品',value:'2'},
        {label:'販促',value:'3'},
        {label:'同捆物',value:'4'},
        {label:'資材',value:'5'},
      ],
      allowPageList:['list','InventoryInfo','InventoryDetail']
    }
  },
  watch:{
    "$route.name":function(newVal){
      if(this.allowPageList.includes(newVal)){
        this.isNewPage = true
        this.getSetList()
      }
      else this.isNewPage = false
    }
  },
  computed:{
    filterList(){
      return this.list.filter(d=>{
        if(this.filterCode==='')
          return d.product_type === this.type
        return d.product_type === this.type && d.product_code === this.filterCode
      })
    }
  },
  methods: {
    async getSetList(){
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = `https://www.bamb.asia/suppliers/get_all_prd_status`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&
        supplier_id=${s_uid}`,
      };

      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            this.list = rep.records.map(d=>{
              return {
                product_code:this.Mosaic(d[0],sessionStorage.getItem('session_id')),
                product_name:this.Mosaic(d[1],sessionStorage.getItem('session_id')),
                product_state:d[2]==='1'?true:false,
                product_type:d[3],
              }
            })
            this.emitHandler(this.list)
          }
        })
    },
    async changeStatus(row){
      
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = `https://www.bamb.asia/suppliers/modification_prd_status`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&obj=`,
      };
      let obj = {
        "supplier_id":`${s_uid}`,
        list:[{
          "product_code":row.product_code,
          "is_show":row.product_state?1:0,
        }]
      }
      config.body += JSON.stringify(obj)
      if (s_id === '3294228999') 
        return
      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            this.emitHandler(row)
          }
        })
    },
    emitHandler(d) {
      this.$eventBus.$emit('getSetState',d)
    },
  },
  mounted() {
    this.getSetList()
  },
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
