import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import EventBus from './eventbus'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Pagination, MessageBox } from 'element-ui'

Vue.config.productionTip = false
Vue.use(Pagination)
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.use(EventBus)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
