<script>
import { Mixin } from '@/mixins/util.js'
export default {
  name: 'login',
  mixins: [Mixin],
  data() {
    return {
      form: {
        ac: '',
        pwd: ''
      },
      remember: false
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  methods: {
    signIn() {
      this.rememberHandler()
      let formdata = new FormData();
      formdata.append('username', this.form.ac);
      formdata.append('password', this.form.pwd);
      const config = {
        method: 'POST',
        // headers: {
        //   'Content-Type': 'text/plain;charset=UTF-8'
        // },
        body: formdata
      }
      const result = this.signInHandler(config)
      result
        .then(res => {
          if (res.status === 200) return res.text()
          else throw new Error('Something went wrong')
        })
        .then(res => {
          const data = JSON.parse(this.RemoveNonValidJSONChars(res))

          if (data.errno === '1' && data.pub_permissions.includes('s')) {
            sessionStorage.setItem('group_uid', data.group_uid)
            sessionStorage.setItem('permission_id', data.permission)
            sessionStorage.setItem('pub_permissions', data.pub_permissions)
            sessionStorage.setItem('session_id', data.session_uid)

            this.get_supplier_info()
          } else {
            this.$alert('請重新輸入帳號密碼', '帳號或密碼錯誤', {
              confirmButtonText: '確定',
              callback: () => {
                this.form.ac = ''
                this.form.pwd = ''
              }
            })
          }
        })
        .catch(() => {
          this.$alert('Some problem happended. Please wait a second', 'Oops', {
            confirmButtonText: '確定'
          })
        })
    },
    get_supplier_info() {
      const sessionid = sessionStorage.getItem('session_id')
      const supplieruid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const config = {
        method: 'POST',
        body: `sessionid=${sessionid}&supplier_uid=${supplieruid}`
      }
      this.getSupplierInfo(config)
        .then(res => res.text())
        .then(rep => {
          const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (data.errno === '1' && data.records.length > 0) {
            if (sessionStorage.getItem('session_id') === '3294228999')
              data.records[0][2] = this.Mosaic(data.records[0][2], '3294228999')
            sessionStorage.setItem('supplier_alias', data.records[0][2])
            sessionStorage.setItem('supplier_type', data.records[0][4])
            sessionStorage.setItem('supplier_img', data.records[0][12])
            this.$router.push({ path: '/dashboard' })
          }
        })
    },
    rememberHandler() {
      if (this.remember) {
        localStorage.setItem('ac', this.form.ac)
        localStorage.setItem('pwd', this.form.pwd)
      } else {
        localStorage.removeItem('ac')
        localStorage.removeItem('pwd')
      }
      localStorage.setItem('remember', this.remember)
    }
  },
  created() {
    // console.log('loging created')
    sessionStorage.clear()
    this.$eventBus.$emit('logOutHandler', false)
    this.$eventBus.$emit('serverDownHandler', false)
    this.remember = localStorage.getItem('remember') === 'true' ? true : false
    this.form.ac =
      localStorage.getItem('ac') === 'null' ? '' : localStorage.getItem('ac')
    this.form.pwd =
      localStorage.getItem('pwd') === 'null' ? '' : localStorage.getItem('pwd')
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
